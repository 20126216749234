<template>
  <div v-loading="loading">
    <div class="maintitle">{{ $route.meta.title }}</div>
    <el-divider></el-divider>
    <el-row :gutter="10" class="text-left">
      <el-col :span="6">
        <el-input v-model="id" placeholder="请输入会员id" size="mini"></el-input>
      </el-col>
      <el-button @click="getInfo" size="mini">查询</el-button>
    </el-row>
    <div v-if="companyName!=''">
      <el-form>
        <el-form-item label="智能体名称" :label-width="formLabelWidth">
          <el-input v-model="companyName" disabled></el-input>
        </el-form-item>
        <el-form-item label="头像" :label-width="formLabelWidth">
          <div style="display: flex;align-items: center">
            <div class="text-left">
              <el-upload :action="HOST + '/admin.index/uploadImageToBaidu?name=logo'" :multiple="false"
                         :data="uploadData"
                         name='logo' :show-file-list="false" :on-success="upload" :before-upload="showLoading"
                         accept=".png,.jpg,.jpeg,.webp">
                <el-button type="primary" plain>
                  <i class="el-icon-upload el-icon--rihgt"></i>点击上传头像，jpg/png格式
                </el-button>
              </el-upload>
              <p style="line-height: 16px;font-size: 12px" class="text-info margin-top">
                1、建议上传200*200像素及以上的企业logo</p>
              <p style="line-height: 16px;font-size: 12px" class="text-info">
                2、图片大小控制在10M以内，支持格式png、jpg、jpeg、webp</p>
            </div>
            <el-image :src="detail.logoUrl" :preview-src-list="[detail.logoUrl]"
                      style="width:100px;aspect-ratio: 1;border:1px solid #E5E5E5;border-radius: 5px;margin-left:10px"
                      fit="contain" v-if="detail.logoUrl !=''"></el-image>
          </div>
        </el-form-item>
        <el-form-item label="服务地区" :label-width="formLabelWidth" class="text-left">
          <el-cascader :options="areaList" value="label" v-model="detail.city" :props="{multiple:true}" collapse-tags clearable @change="cityChange"></el-cascader>
        </el-form-item>
        <el-form-item label="智能体简介" :label-width="formLabelWidth">
          <el-input v-model="detail.overview">
            <template slot="append">
              <el-button @click="createInfo('overview')">AI生成</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="性格特征" :label-width="formLabelWidth" class="text-left">
          <el-select v-model="detail.characterFeature">
            <el-option value="concise" label="简洁"></el-option>
            <el-option value="detailed" label="详细"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开场白" :label-width="formLabelWidth">
          <el-input v-model="detail.openRemark">
            <template slot="append">
              <el-button @click="createInfo('openRemark')">AI生成</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="开场白问题" :label-width="formLabelWidth" class="text-left">
          <p style="line-height: 16px;font-size: 12px" class="text-info">1、问题最少添加3个(少于3个则无法审核通过)
            、最多添加5个，超出后随机展示其中3个问题</p>
          <p style="line-height: 16px;font-size: 12px" class="text-info margin-bottom">
            2、只设置引导问题即可，无需填写答案，用户点击问题后，会跳转至智能体对话框并智能生成回答</p>
          <div v-for="(item,index) in detail.openquestions" :key="index">
            <p>问题{{ index + 1 }}
              <el-button class="margin-left" type="danger" size="mini" @click="deleteQuestion(index)">删除</el-button>
            </p>
            <el-input v-model="item.question" maxlength="50" show-word-limit>
              <template slot="append">
                <el-button @click="createQuestion(index)">AI生成</el-button>
              </template>
            </el-input>
          </div>
          <el-button @click="addQuestion" v-if="detail.openquestions.length<5" class="margin-top" type="success">
            添加问题
          </el-button>
        </el-form-item>
      </el-form>
      <el-button @click="update(0)">保存信息</el-button>
      <el-button @click="update(1)">{{ detail.appId == '' ? '开通智能体' : '更新智能体' }}</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      detail: {
        id: '',
        member_id: this.id,
        appId: '',
        city: '',
        logoUrl: '',
        name: '',
        openRemark: '',
        overview: '',
        openquestions: [],
        characterFeature: '',
      },
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      loading: false,
      companyName: '',
      isIndeterminate: false,
      checkAll: false,
      areaList:[],
    }
  },
  methods: {
    showLoading() {
      this.loading = true;
    },
    getInfo() {
      if(this.id == ''){
        this.$message({
          type:'error',
          message:'请输入会员member_id'
        })
        return;
      }
      this.$axios({
        url: this.HOST + "/admin.membagent/info",
        method: "post",
        data: {
          member_id: this.id
        }
      }).then(res => { console.log(res.data);
            if (res.data.status == 1) {
              if (res.data.agentStatus == 0) {
                this.$message({
                  type: 'warning',
                  message: '商户暂未开通智能体'
                })
              } else {
                this.detail = res.data.detail;
              } console.log(this.detail)
              this.companyName = res.data.company;
              this.$axios({
                url: this.HOST + "/admin.membagent/getArea",
                data:{
                  member_id:this.id
                },
                method:'post'
              }).then(rest => {
                if (rest.data.status == 1) {
                  this.areaList = rest.data.list
                }
              })
            }
          }
      )
    },
    addQuestion() {
      if (this.detail.openquestions.length >= 5) {
        this.$message({
          type: "warning",
          message: '开场白问题最多5个'
        })
        return;
      }
      this.detail.openquestions.push({
        member_id: this.id,
        question: '',
        type: 1
      })
    },
    cityChange(e){
      // this.detail.city = e;
    },
    update(status) {
      let list = [];
      for (let i in this.detail.openquestions) {
        if (this.detail.openquestions[i].question != '') {
          list.push(this.detail.openquestions[i])
        }
      }
      if (list.length < 3) {
        this.$message({
          type: "warning",
          message: '开场白问题最少3个'
        })
        return;
      }
      if (list.length > 5) {
        this.$message({
          type: "warning",
          message: '开场白问题最多5个'
        })
        return;
      }
      this.detail.openquestions = list;
      this.showLoading();
      this.$axios({
        url: this.HOST + "/admin.membagent/saveInfo",
        method: "post",
        data: {
          info: this.detail,
          update: status
        }
      }).then(res => {
        this.loading = false;
      })
    },
    upload(res) {
      this.loading = false;
      this.detail.logoUrl = res.fileUrl
    },
    deleteQuestion(index) {
      this.detail.openquestions.splice(index, 1)
    },
    createInfo(item) {
      this.showLoading()
      this.$axios({
        url: this.HOST + '/admin.membagent/createInfo',
        method: 'post',
        data: {
          member_id: this.id,
          item: item
        }
      }).then(res => {
        this.loading = false
        if (res.data.status == 1) {
          this.detail[item] = res.data.info
        }
      })
    },
    createQuestion(index){
      this.showLoading()
      this.$axios({
        url: this.HOST + '/admin.membagent/createQuestion',
        method: 'post',
        data: {
          member_id: this.id,
          question: this.detail.openquestions[index].question
        }
      }).then(res => {
        this.loading = false
        if (res.data.status == 1) {
          this.detail.openquestions[index].question = res.data.info
        }
      })
    },
  },
  mounted() {
    this.id = this.$route.query.id || '';
    console.log(this.id)
    if (this.id != '') this.getInfo();
  }
}
</script>