<template>
  <div>
    <div class="maintitle">{{ $route.meta.title }}</div>
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-input v-model="keyword" placeholder="请输入会员id或公司全名" size="mini"></el-input>
      </el-col>
      <el-col :span="6" class="text-left">
        <el-button @click="searchRecord" size="mini">查询</el-button>
        <el-button @click="refresh" size="mini">更新</el-button>
      </el-col>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column prop="time" label="购买时间"></el-table-column>
      <el-table-column prop="pay_time" label="付款时间"></el-table-column>
      <el-table-column :formatter="formatStatus" label="支付状态"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="text" v-for="(item,index) in scope.row.invoice_urls" :key="index" @click="openUrl(item)">开发票</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list:[],
      page:1,
      total:0,
      keyword:''
    }
  },
  methods: {
    getList(){
      this.$axios({
        url:this.HOST+'/admin.membagent/record',
        method:'post',
        data:{
          keyword:this.keyword,
          page:this.page,
          pagesize:this.pagesize
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      })
    },
    formatStatus(row){
      return row.status == 0?'未支付':'已支付';
    },
    searchRecord(){
      this.page = 1;
      this.getList();
    },
    refresh(){
      this.$axios({
        url:this.HOST+'/admin.membagent/updateRecord',
        method:'post',
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'success',
            message:res.data.info
          })
          this.page=1;
          this.getList();
        }
      })
    },
    openUrl(url){ console.log(url);
      window.open(url,'_blank')
    }
  },
  mounted() {
    this.getList();
  }
}
</script>