<template>
  <div v-loading="loading">
    <div class="maintitle">{{ $route.meta.title }}</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-input v-model="id" placeholder="请输入会员id或公司全称，可输入多个，中间用英文逗号隔开，必须保证为同一地区客户" size="mini" style="width:500px"></el-input>
      <el-button class="margin-left" @click="buyAgent" size="mini">购买</el-button>
      <el-button class="margin-left" @click="getPayUrl" size="mini">查询未支付订单</el-button>
    </el-row>
    <el-dialog :visible.sync="payUrlDiv" width="80%" :close-on-click-modal="false" @close="notice=''">
      <div class="text-left">
        <div v-for="(item,index) in payUrl" :key="index" class=" margin-large-bottom">
          <h1 style="margin-bottom:10px">{{item.area_text}}<span style="color:red;margin-left:10px">{{notice}}</span></h1>
          <div v-if="item.data.length>0">
            <span v-for="(it,id) in item.data" :key="id" style="display: inline-block;border-radius: 10px;margin:10px 5px;padding:10px 20px;border:1px solid #E5E5E5">{{it.company}}</span>
          </div>
          <div v-else>无待支付订单</div>
          <div v-if="item.pay != ''" class="margin-top">
            <el-button size="mini" type="success" @click="pay(item.pay)">支付</el-button>
            <el-button size="mini" type="danger" @click="cancel(item)">取消订单</el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="payUrlDiv = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      loading: false,
      payUrlDiv:false,
      payUrl:[],
      notice:''
    }
  },
  methods: {
    showLoading() {
      this.loading = true;
    },
    buyAgent(){
      if(this.id == ''){
        this.$message({
          type:'error',
          message:'请输入会员member_id'
        })
        return;
      }
      this.showLoading()
      this.$axios({
        url: this.HOST + '/admin.membagent/buyAgent',
        method: 'post',
        data: {
          member_id: this.id,
        }
      }).then(res => {
        this.loading = false
        if (res.data.status == 1) {
          this.payUrl = res.data.result;
          this.notice = res.data.notice;
          this.payUrlDiv = true;
        }
      })
    },
    getPayUrl(){
      this.showLoading()
      this.$axios({
        url: this.HOST + '/admin.membagent/getPayUrl',
        method: 'post',
      }).then(res => {
        this.loading = false
        console.log(res.data)
        if (res.data.status == 1) {
          this.payUrl = res.data.result;
          this.payUrlDiv = true;
        }
      })
    },
    pay(url){
      window.open(url,'_blank');
      this.payUrlDiv = false;
    },
    cancel(item){
      this.$confirm(`确定要取消${item.area_text}地区的智能体订单吗？`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/admin.membagent/cancelOrder",
          method: "post",
          data: {
            area:item.area
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.payUrlDiv = false;
          }
        });
      });
    }
  },
  mounted() {
  }
}
</script>